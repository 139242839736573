import {LiveBoard} from "../components/LiveBoard";
import {
    type FlzEvent,
    FLZ_EVENT_ACTION,
    FLZ_WIDGET_EVENT_TYPE,
    FlzBoardEvent,
    PUBLIC_INCOMING_EVENT_ACTION, PUBLIC_OUTGOING_EVENT_ACTION,
} from "client-sdk";
import {LiveBoardController} from "../controllers/LiveBoardController";
import {EventEmitter} from "events";

export default class BoardEventsHandlers extends EventEmitter {

    private board: LiveBoard;
    private isListening: boolean = false;
    private eventHandler: any;
    private controller: LiveBoardController;
    private stopPropagation: boolean;

    constructor(el: LiveBoard, controller: LiveBoardController) {
        super();
        this.board = el;
        this.controller = controller;
        this.beforeUnloadHandler = this.beforeUnloadHandler.bind(this);
    }

    public startListening() {
        this.eventHandler = this.mainEventHandler.bind(this);
        this.board.addEventListener(FLZ_WIDGET_EVENT_TYPE, this.eventHandler);

        const publicHandler = this.publicEventHandler.bind(this);
        window.addEventListener("Folloze.consentGiven", publicHandler);
        window.addEventListener("Folloze.stopTrackingForVisit", publicHandler);
        window.addEventListener("Folloze.triggerCtaSubmit", publicHandler);
        window.addEventListener("Folloze.closeFormRequest", publicHandler);
        window.addEventListener("beforeunload", this.beforeUnloadHandler);
        this.isListening = true;
        console.debug("board events handlers are online & listening");
    }

    public stopListening() {
        this.board.removeEventListener(FLZ_WIDGET_EVENT_TYPE, this.eventHandler);
        window.removeEventListener("Folloze.consentGiven", this.publicEventHandler);
        window.removeEventListener("Folloze.stopTrackingForVisit", this.publicEventHandler);
        window.removeEventListener("Folloze.triggerCtaSubmit", this.publicEventHandler);
        window.removeEventListener("Folloze.closeFormRequest", this.publicEventHandler);
        window.removeEventListener("beforeunload", this.beforeUnloadHandler);
        this.isListening = false;
    }

    private async mainEventHandler(e: FlzEvent) {
        this.stopPropagation = true;
        switch (e.action as FLZ_EVENT_ACTION) {
            case "resize":                              {this.stopPropagation = false;} break;
            case "append-to-liveboard":                 this.appendToLiveBoard(e); break;
            case "append-to-liveboard-light-dom":       this.appendToLiveBoardLightDOM(e); break;
            case "get-current-item":                    this.controller.getCurrentItem(e); break;
            case "get-initial-journey-item":            this.controller.getInitialJourneyItem(e); break;
            case "openItemViewer":                      this.controller.openItemViewer(e); break;
            case "get-modal-element":                   this.controller.getModalElement(e); break;
            case "open-modal":                          this.controller.openModal(e); break;
            case "close-modal":                         this.controller.closeModal(); break;
            case "closeItemViewerRequest":              this.controller.closeItemViewer(); break;
            case "itemViewerClosed":                    this.controller.itemViewerClosed(e); break;
            case "changeItem":                          this.controller.changeItem(e); break;
            case "get-category-slug":                   this.controller.getCurrentCategorySlug(e); break;
            case "get-all-categories":                  this.controller.getAllCategories(e); break;
            case "get-item":                            this.controller.getItem(e); break;
            case "get-items":                           this.controller.getItems(e); break;
            case "get-lead":                            this.controller.getLead(e); break;
            case "change-category":                     this.controller.changeCategory(e); break;
            case "get-journey":                         this.controller.getJourney(e); break;
            case "open-link-by-target-type":            this.controller.openLinkByTargetType(e.payload); break;
            case "get-file-metadata":                   this.controller.getFileMetadata(e); break;
            case "get-file-download-url":               this.controller.getFileDownloadUrl(e); break;
            case "get-footers":                         this.controller.getFooters(e); break;
            case "get-org-header-config":               this.controller.getOrgHeaderConfig(e); break;
            case "stop-tracking-for-visit":             this.controller.stopTrackingForSession(e); break;
            case "ctaClick":                            this.ctaClickHandler(e); break;
            case "ctaSubmit":                           this.ctaSubmitHandler(e); break;
            case "consent-given":                       this.consentGivenHandler(e); break;
            case "getFormData":                         this.controller.getFormData(e); break;
            case "get-form-privacy-message":            this.controller.getFormPrivacyMessage(e); break;
            case "close-form-request":                  this.controller.formCloseRequest(e); break;
            case "register-floating-widgets-triggers":  this.stopWidgetsNotification(e); break;
            case "get-contact-card-info":               this.controller.getContactCardInfo(e); break;
            case "share-by-email":                      this.controller.shareHandler(e); break;
            case "get-privacy-messages":                this.controller.getPrivacyMessages(e); break;
            case "widgets-scripts-loaded":              this.handleWidgetsLoaded(); break;
            case "get-state":                           this.controller.handleGetState(e); break;
            case "floating-widget-manager":             {e.skipWidgetsNotify = false;} break;
            case "widget-updated":                      this.controller.handleWidgetUpdated(e); break;
            // todo: this should be uncommented but for some reason it affects the designer autosave
            // case "widget-update":                       {e.skipWidgetsNotify = true;} break;
            // case "widget-first-updated":                {e.skipWidgetsNotify = true;} break;
            case "track-lead-event":                    this.controller.analyticsController.trackLeadEvent(e); break;
            case "analytic-event":                      this.handleAnalyticsEvents(e); break;
            case "link-click":                          this.linkClickHandler(e); break;
            case "get-is-regulated-country":            this.controller.getIsRegulatedCountry(e); break;
            case "load-add-to-calendar":                this.controller.loadAddToCalendarScript(e); break;
            case "get-public-url":                      this.controller.getPublicUrl(e); break;
            case "load-chat-script":                    this.controller.loadChatScript(e); break;
            case "create-chat-user":                    this.controller.createChatUser(e); break;
            case "join-chat-conversation":              this.controller.joinChatConversation(e); break;
            case "leave-chat-conversation":             this.controller.leaveChatConversation(e); break;
            case "track-live-event-attendance":         this.controller.analyticsController.trackLiveEventAttendance(e); break;
            case "get-live-event-participants":         this.controller.getLiveEventParticipants(e); break;
            case "join-live-event":                     this.controller.joinLiveEvent(e); break;
            case "leave-live-event":                    this.controller.leaveLiveEvent(e); break;

            default: this.defaultHandler(e);
        }

        if(this.stopPropagation) {
            e.stopPropagation();
        }

        // notify any one listening on the events handler (floating widget manager)
        this.emit(e.action, e);

        if (e.skipWidgetsNotify) {
            return;
        }

        // pass to all widgets
        this.controller.notifyWidgets(e);
    }

    private handleWidgetsLoaded() {
        this.stopPropagation = false;
        this.controller.setBoardLoaded(true);
        this.controller.initializeCookieConsent();
    }

    private publicEventHandler(e: CustomEvent) {
        switch (e.type as PUBLIC_INCOMING_EVENT_ACTION) {
            case "Folloze.consentGiven": {
                // @ts-ignore
                const event = new FlzBoardEvent(window, "consent-given", {
                    ...e.detail,
                    consentOrigin: "Folloze.consentGiven"
                });
                this.board.dispatchEvent(event);
                break;
            }
            case "Folloze.stopTrackingForVisit": {
                // @ts-ignore
                const event = new FlzBoardEvent(window, "stop-tracking-for-visit", e.detail);
                this.board.dispatchEvent(event);
                break;
            }
            case "Folloze.triggerCtaSubmit": {
                const lead = this.controller.getLeadFromState();
                // @ts-ignore
                const event = new FlzBoardEvent(window, "ctaSubmit", {
                    cta: {
                      area: e.detail.area || null,
                      label: e.detail.label || null
                    },
                    email: e.detail.email || lead?.email,
                    type: e.detail.type || null,
                    link: e.detail.link || null,
                    name: e.detail.name || null,
                    last_name: e.detail.last_name || null,
                    company: e.detail.company || null,
                    headline: e.detail.headline || null,
                    note: e.detail.note || null,
                    phone: e.detail.phone || null,
                });
                this.board.dispatchEvent(event);
                break;
            }
            case "Folloze.closeFormRequest": {
                const emitter = e.detail.emitter ? e.detail.emitter : document;
                const event = new FlzBoardEvent(emitter, "close-form-request", {slotId: e.detail.slotId});
                this.board.dispatchEvent(event);
                break;
            }
            default:
                break;
        }
    }

    private stopWidgetsNotification(e: FlzEvent) {
        e.skipWidgetsNotify = true;
        return e;
    }

    /**
     * here we will connect all widgets / components that are global and not a part of the board config (layout)
     */
    private appendToLiveBoard(e: FlzEvent) {
        const element = e.payload.element;
        if (element) {
            if (e.payload.accessor) {
                // @ts-ignore
                this.board[e.payload.accessor] = element;
            }
            this.board.shadowRoot?.appendChild(element);
        }
    }

    private appendToLiveBoardLightDOM(e: FlzEvent) {
        const element = e.payload.element;
        if (element) {
            if (e.payload.accessor) {
                // @ts-ignore
                this.board[e.payload.accessor] = element;
            }
            this.board.appendChild(element);
        }
    }

    private ctaClickHandler(e: FlzEvent) {
        this.publicEmit("Folloze.ctaClick", e.payload);
        this.controller.analyticsController.trackCtaClick(e);
    }

    private ctaSubmitHandler(e: FlzEvent) {
        this.controller.ctaSubmitHandler(e);
        this.publicEmit("Folloze.ctaSubmit", e.payload);
        this.controller.analyticsController.trackCtaSubmit(e);
    }

    private linkClickHandler(e: FlzEvent) {
        this.publicEmit("Folloze.linkClick", e.payload);
    }

    private handleAnalyticsEvents(e: FlzEvent) {
        const event = e.payload.type;
        this.controller.analyticsService.addToQueue(e);
        if (event === "video_started" || event === "video_ended") {
            this.controller.analyticsController.trackVideoSectionDuration(e);
        }
    }

    private consentGivenHandler(e: FlzEvent) {
        const externalConsent = e.payload.consentOrigin == "Folloze.consentGiven";
        if (externalConsent) {
            this.controller.cookiesConsentService.setLeadCookieWithConsent(e);
        } else {
            this.controller.cookiesConsentService.setCookiesConsent(e);
        }

        this.publicEmit("Folloze.onConsent");
    }

    private beforeUnloadHandler() {
        const flzEvent = new FlzBoardEvent(this.board, "window-before-unload", {});
        this.controller.notifyWidgets(flzEvent);
    }

    private defaultHandler(e: FlzEvent) {
        this.stopPropagation = false;
    }

    private publicEmit(eventType: PUBLIC_OUTGOING_EVENT_ACTION, data?: any) {
        // dispatch event
        if (typeof CustomEvent !== "function") {
            return;
        }

        const event = new CustomEvent(eventType, {
            bubbles: true,
            cancelable: true,
            detail: data?.publicEventPayload || data
        });

        document.dispatchEvent(event);
    }
}

// @ts-nocheck
const ProviderScripts = {
    ga: {
        register: function(obj){
            const id = obj.code;
            let hasLoaded = false;
            const gtagUrl = `https://www.googletagmanager.com/gtag/js?id=${id}`;
            const pending = [];
            const sendTrackingData = (event)=>{
                gtag('event', event.name, event.data);
            };
            const loaded = ()=>{
                if(hasLoaded) return;
                hasLoaded = true;
                while(pending.length)
                    sendTrackingData(pending.shift());
            };
            (function(){
                if(!obj.active) {
                  loaded();
                  return;
                }

                const s = document.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = gtagUrl;

                s.onload = function(){
                  loaded();
                };

                document.getElementsByTagName('head')[0].appendChild(s);

                let inlineScript = document.createElement("script");
                inlineScript.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${id}');
                `;

                document.head.appendChild(inlineScript);
            })();

            return function track(event){
                if(hasLoaded){
                    sendTrackingData(event);
                } else {
                    pending.push(event);
                }
            };
        },
        isActive: (settings) => {
          return (settings && settings.active) || typeof window.gtag === "function";
        }
        
    },
    munchkin: {
        register: function(obj){
            const id = obj.code;
            const pending = [];
            let didInit;

            (function() {
                function initMunchkin() {
                    if(!didInit) {
                        didInit = true;
                        window["Munchkin"].init(id);

                        while(pending.length)
                            track(pending.shift());
                    }
                }
                const s = document.createElement('script');
                s.type = 'text/javascript';
                s.src = '//munchkin.marketo.net/munchkin.js';
                //@ts-ignore
                s.onreadystatechange = function() {
                    if (this.readyState == 'complete' || this.readyState == 'loaded') {
                        initMunchkin();
                    }
                };
                s.onload = initMunchkin;
                document.getElementsByTagName('head')[0].appendChild(s);
            })();

            function track(event){
                if(event.name != 'page_view') {
                  return;
                }

                if(window["Munchkin"] && typeof(window["Munchkin"].munchkinFunction) == "function") {
                    window["Munchkin"].munchkinFunction("visitWebPage", event.data);
                }
                else {
                    pending.push(event);
                }
            }

            return track;
        }
    },
    eloqua: {
        register: function(obj){
            const id = obj.code;
            window["_elqQ"] = window["_elqQ"] || [];
            window["_elqQ"].push(['elqSetSiteId', id]);
            window["_elqQ"].push(['elqTrackPageView']);

            (function() {
                const s = document.createElement('script'); s.type = 'text/javascript';
                s.async = true; s.src = '//img.en25.com/i/elqCfg.min.js';
                const x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            })();

            return function track(event){
              if(event.name != 'page_view') {
                return;
              }
                window["_elqQ"].push(['elqTrackPageView', event.data.url]);
            };
        }
    },
    pardot: {
        register: function(obj){
            window["piAId"] = obj.pi_aid;
            window["piCId"] = obj.pi_cid;
            window["piHostname"] = obj.pi_hostname;

            (function() {

                function async_load(){
                    const s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                    const c = document.getElementsByTagName('script')[0];
                    c.parentNode.insertBefore(s, c);
                }

                async_load();
            })();
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return function track(page) {};
        }
    }
};

export default class CampaignAnalytics {
    private trackings: any[];
    private isPreview: boolean;

    constructor(isPreview: boolean) {
        this.trackings = [];
        this.isPreview = isPreview;
    }

    register(analyticsSettings) {
        if (this.isPreview) {
            return;
        }

        for(const provider in analyticsSettings) {
            const isActive = ProviderScripts[provider].isActive ? ProviderScripts[provider].isActive(analyticsSettings[provider]) :
                                                                  (analyticsSettings[provider] && analyticsSettings[provider].active);

            if(!isActive) { 
                continue;
            }

            const track = ProviderScripts[provider].register(
                analyticsSettings[provider]
            );

            this.trackings.push(track);
        }
    }

    track(event) {
        for(let i = 0; i < this.trackings.length; i++) {
            this.trackings[i](event);
        }
    }
}